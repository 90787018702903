import styled from "styled-components"
import { md } from "mill/utils/breakpoints"

export const Background = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-color: ${props => props.theme.colors.primary};
  display: flex;
  align-items: center;
  position: relative;
`

export const YarnoLogo = styled.img`
  position: absolute;
  top: 1rem;
  left: 2rem;
  max-height: 2.4rem;

  @media ${md} {
    max-height: 2.4rem;
  }
`

export const Container = styled.div`
  text-align: center;
  margin: 0 auto;
  color: white;
  padding: 3rem;
  vertical-align: middle;
  max-width: 96rem;

  img {
    max-height: 26rem;
    max-width: 26rem;
  }

  h1 {
    font-size: 3rem;
  }

  a {
    color: white;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }

  @media ${md} {
    padding: 5rem;

    img {
      max-height: none;
      max-width: none;
    }

    h1 {
      font-size: 2em;
    }
  }
`

export const ResendInvite = styled.div`
  margin-top: 4rem;

  p {
    margin-bottom: 1rem;

    a {
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  span:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  i {
    padding-right: 1rem;
  }
`
